import {
  AboutContainer,
} from './styles';

export default function About() {
  return (
    <AboutContainer id="About">
      <h1>Sobre</h1>
      <span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nun
        c vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti
        sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur
        tempus urna at turpis condimentum lobortis.
      </span>
      <span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nun
        c vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu,
      </span>
    </AboutContainer>
  );
}
